(<template>
  <div class="blocked-by-availability"
       v-html="messageText">
  </div>
</template>)

<script>
  export default {
    computed: {
      messageText() {
        return this.$gettext('According to <button class="js-settings-link blocked-by-availability__link">your settings</button>, you have set some time off during this assignment. Please edit your time off settings to apply for this job. You are seeing this job because it was added to your dashboard before you registered your time off. You will not be invited to any new jobs during your time off.');
      }
    },
    methods: {
      goToAvailabilityPage() {
        this.$router.push(this.$makeRoute({name: 'ServerPreferencesAvailability'}));
      }
    },
    mounted() {
      const linkElement = document.querySelector('.js-settings-link');
      if (linkElement) {
        linkElement.addEventListener('click', this.goToAvailabilityPage);
      }
    }
  };
</script>

<style>
  .blocked-by-availability__link {
    color: #000;
    text-decoration: underline;
  }
</style>

<style scoped>
  .blocked-by-availability {
    padding: 30px 90px;
    background-color: #fff5d1;
    color: #f93;
    line-height: 1.64;
  }

  @media (max-width: 768px) {
    .blocked-by-availability {
      padding: 20px 15px;
    }
  }
</style>
